(function($){
    $(document).ready(function(){
        smoothScroll();
        offers();

        var hash = window.location.hash;
        if (hash){
            var target = $(hash);
            var expandBtn = target.find('.toggle-list-item');
            // zmiana z document ready
            $(window).load(function () {
                $('html, body').animate({
                        scrollTop: target.offset().top - 80
                    }, 1000);
                if (expandBtn.length) {
                    expandBtn.click();
                }
            });
        }


        $('#main-menu > ul').smartmenus({
            markCurrentItem: true,
            showFunction: function($ul, complete) { $ul.slideDown(200, complete); },
            hideFunction: function($ul, complete) { $ul.slideUp(200, complete); }
        });

        $('.menu-toggle').click(function(){
            $('#header nav').slideToggle();
        });

    });

    function offers(){
        $('.toggle-list-item').click(function(){
            $(this).prev().slideToggle();
            $(this).toggleClass('opened').parent().toggleClass('opened');
        });
    }

    function smoothScroll() {
        $('a[href*="#"]:not([href="#"]):not(.no-scroll)').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                var expandBtn = target.find('.toggle-list-item');
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 30
                    }, 1000);
                    if (expandBtn.length) {
                        expandBtn.click();
                    }
                    return false;
                }

            }
        });
    }
})(jQuery);
